import { useQuery } from "@tanstack/react-query";
import { defaultCompanyChoice, useCompanyChoice } from "Components/Contexts/CompanyChoiceContext";
import { loadCompanies } from "functions/customHooks/api/queries/companyQueries";
import { useTranslation } from "react-i18next";
import { CompanyType } from "type/mainTypes";
import { MouseEvent, useEffect } from "react";

import { DefaultDarkSmallLogo } from "UI/Logo/DefaultLogo";
import { AppConfig } from "config/AppConfig";

type Props = {
    onSelect?: () => void
};

export const CompanyChoiceForm = ({
    onSelect
}: Props) => {
    const {t} = useTranslation();

    const {companyChoice, setCompanyChoice} = useCompanyChoice();

    const {data: companies, isFetching} = useQuery({
        queryKey: ['companies_index'],
        queryFn: loadCompanies,
        initialData: null
    });

    useEffect(() => {
        if(!companyChoice || (companyChoice.id === defaultCompanyChoice.id)) {
            if(companies && companies.length >= 1) {
                setCompanyChoice(companies[0]);
            }
        }
        // eslint-disable-next-line
    }, [companies]);

    const handleSelect = (selectedCompany: CompanyType) => {
        setCompanyChoice(selectedCompany);
        if(onSelect) {
            onSelect();
        }
    }

    return (
        <div className="auth-logo">
            {
                isFetching && (
                    <div style={{height: '150px', width: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {t('company_choices_loading')}
                    </div>
                )
            }
            {
                companies && companies.map(company => (
                    <CompanyChoiceField
                        key={company.id}
                        company={company}
                        isSelected={companyChoice.id === company.id}
                        onSelect={handleSelect}
                    />
                ))
            }
        </div>
    )
}


type CompanyChoiceFieldProps = {
    company: CompanyType,
    isSelected: boolean,
    onSelect: (company: CompanyType) => void
}

const CompanyChoiceField = ({
    company,
    isSelected,
    onSelect,
}: CompanyChoiceFieldProps) => {
    const {t} = useTranslation();

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        onSelect(company);
    }

    return (
        <button type="button" className={'btn company-choice-item' + (isSelected ? ' selected': '')} onClick={handleClick}>
            {
                company?.logo?.filePath ? (
                    <img src={AppConfig.API_BASE_URL + '/' + company.logo.filePath} alt={t('logo')} height={50} />
                ): (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <DefaultDarkSmallLogo />
                    </div>
                )
            }
            <div className="company-choice-item-title">{company.name}</div>
        </button>
    )
}
