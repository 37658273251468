
export class AppConfig {
    
    //SITE
    static SITE_URL: string = process.env.REACT_APP_SITE_URL ?? 'http://localhost';

    //API
    static API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost';

    //AUTH
    // auth cookie expiration time (seconds) ---> remember me
    static AUTH_COOKIE_EXPIRATION_TIME: number = 3600 * 24 * 30 * 6; // 6 months

    //VIEW

    //locale
    static DEFAULT_LOCALE: string = 'fr';

    //brand
    //company choice
    //default value when company have not been choosen yet
    static DEFAULT_COMPANY_NAME: string = 'France Sport';

    //title 
    static DEFAULT_HTML_TITLE: string = 'Back-office';

    //menu
    //pages that will be always displayed
    static DEFAULT_MENU: string[] = ['home', 'error_404', 'login', 'logout', 'profile', 'change_password', 'password_reset', 'forgotten_password', 'users'];

    //alerts
    static ALERT_DURATION: number = 8000;

    //notifications
    static NOTIFICATIONS_API_CALL_INTERVAL: number = 30000;

    //timezone
    static DEFAULT_TIMEZONE: string = 'Europe/Paris';

    //orders
    static ORDER_TYPE_APP = 'son';
}
