import { AppConfig } from "config/AppConfig";
import { formatDistanceToNowStrict } from "date-fns";
import { parseFromTimeZone } from 'date-fns-timezone';
import { fr } from 'date-fns/locale';
import { enUS } from 'date-fns/locale';
import { useTranslation } from "react-i18next";

type Props = {
    dateString: string
}

export const DateShow = ({
    dateString
}: Props) => {
    const {i18n} = useTranslation();
    const locale = i18n.language === 'fr' ? fr: enUS;

    const date = parseFromTimeZone(dateString, { timeZone: AppConfig.DEFAULT_TIMEZONE });

    return formatDistanceToNowStrict(date, {addSuffix: true, locale});
}
