import { Error404 } from "pages/error/Error404";
import { Logout } from "pages/Authentication/Logout";
import { Login } from "pages/Authentication/Login";
import { ReactElement } from "react";
import Dashboard from "pages/Dashboard";


export type AppRoute = {
  name: string,
  path: string,
  component?: ReactElement,
  title: string,
  withLayout?: boolean,
  import?: string
};

/**
 * titles will be translated
 * 
 * 
 * routes must have import or component property
 * 
 * routes with component are not lazy routes
 * 
 * routes with import are lazy routes
 * (import base = '/src/pages/')
 */


/**
 * PUBLIC ROUTES
 */
export const publicRoutes: AppRoute[] = [
  { name: 'login', path: '/login', component: <Login />, title: 'login', withLayout: false },
  { name: 'logout', path: '/logout', component: <Logout />, title: 'logout' },
  { name: 'forgotten_password', path: '/auth/forgotten-password', import: 'Authentication/ForgottenPassword', title: 'password_reset' },
  { name: 'password_reset', path: '/auth/password-reset', import: 'Authentication/PasswordReset', title: 'password_reset' },
];


/**
 * AUTHENTICATION REQUIRED ROUTES
 */
export const authRoutes: AppRoute[] = [

  //BASE ROUTES

  { name: 'home', path: '/', component: <Dashboard />, title: 'dashboard' },
  
  //menu
  { name: 'profile', path: '/profile', import: 'Profile', title: 'profile' },
  
  //account
  { name: 'change_password', path: '/profile/password-change', import: 'Authentication/PasswordChange', title: 'change_password' },

  //cruds 
  { name: 'users', path: '/administration/users/*', import: 'Administration/Users', title: 'users' },
  
  //error
  { name: 'error_404', path: '/error404', component: <Error404 />, title: 'Error 404', withLayout: false },
  

  //FSP SPECIFIC ROUTES

  //items
  { name: 'items_items', path: '/items/items/*', import: 'Items/Items', title: 'items' },
  { name: 'items_families', path: '/items/families/*', import: 'Items/Families', title: 'families' },
  { name: 'items_collections', path: '/items/collections/*', import: 'Items/Collections', title: 'collections' },
  { name: 'priceLists', path: '/items/priceLists/*', import: 'Items/PriceLists', title: 'priceLists' },
  
  //thirdParts
  { name: 'third_part_categories', path: '/third_parts/third_part_categories/*', import: 'ThirdParts/ThirdPartCategories', title: 'thirdPartCategories' },
  { name: 'customers', path: '/third_parts/customers/*', import: 'ThirdParts/Customers', title: 'customers' },

  //contacts
  { name: 'contacts', path: '/contacts/*', import: 'Contacts', title: 'contacts' },

  //parameters
  { name: 'companies', path: '/parameters/companies/*', import: 'Parameters/Companies', title: 'companies' },
  { name: 'payment_terms', path: '/parameters/payment_terms/*', import: 'Parameters/PaymentTerms', title: 'paymentTerms' },
  { name: 'vat_rates', path: '/parameters/vat_rates/*', import: 'Parameters/VatRates', title: 'vatRates' },
  { name: 'shipping_costs', path: '/parameters/shipping_costs/*', import: 'Parameters/ShippingCosts', title: 'shippingCosts' },

  //orders
  { name: 'orders', path: '/orders/*', import: 'Orders', title: 'orders' },
  
  //delivery_notes
  { name: 'delivery_notes', path: '/delivery_notes/*', import: 'DeliveryNotes', title: 'deliveryNotes' },
];
